<template>
<div class="bg-gray-300 md:p-5">
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class=" px-4 pt-5 pb-0 sm:px-6">
    <h3 class="text-lg leading-6 font-medium text-gray-900">
      Personaliza tu pedido {{ message}}
    </h3>
    <addons v-model="addons"/>
  </div>

  <div class="mx-5 p-3 bg-white rounded shadow-sm text-right font-semibold">
    Total
    <w-price :value="addons ? addons.totalPrice : 0" />
  </div>
  <div class="text-xs m-5 rounded bg-black text-white p-2">
    <pre>{{addons}}</pre>
  </div>

</div>
</template>
<script>
import Addons from '@/components/demo/Addons'
export default {
  layout: 'login',
  components: {
    Addons,
  },
  data() {
    return {
      date: null,
      priceChar: 10,
      message: "",
      addons: {},
      currentAddon: "custom-sign"
    }
  },
  computed: {
    price() {
      if(this.currentAddon === "voice-message") {
        return this.message.length * this.priceChar
      }
      if(this.currentAddon === "custom-sign") {
        return 299
      }
      return 999;
    }
  },
  methods: {
    clear(){
      this.message = "";
      this.currentAddon = null
    },
    showModal(key){
      this.clear();

      // Verificamos si existe para editarlo.
      if(this.exists(key)) {
        this.message = this.addons[key].message;
      } else {
        this.message = ""
      }

      this.currentAddon = key;
    },
    exists(key){
      return this.addons.findIndex(addon => addon.type === key) !== -1;
    },
    addVoiceMessage() {
      let exists = this.addons.findIndex(addon => addon.type === "voice-message")
      if(exists > -1){
        this.addons.splice(exists, 1);
      }
      this.addons.push({
        type: "voice-message",
        message: this.message,
        price: this.price
      })
      this.clear();
    },
    addSign() {
      let exists = this.addons.findIndex(addon => addon.type === "custom-sign")
      console.log(exists)
      if(exists > -1){
        this.addons.splice(exists, 1);
      }
      this.addons.push({
        type: "custom-sign",
        message: this.message,
        price: this.price
      })
      this.clear();
    },
    addMessageCard() {
      let exists = this.addons.findIndex(addon => addon.type === "message-card")
      console.log(exists)
      if(exists > -1){
        this.addons.splice(exists, 1);
      }
      this.addons.push({
        type: "message-card",
        message: this.message,
        price: this.price
      })
      this.clear();
    }
  },
}
</script>
