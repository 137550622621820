<template>
<div class="">
<!-- This example requires Tailwind CSS v2.0+ -->
<div v-if="currentAddon === 'voice-message'" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
      <div>
        <div class="sm:col-span-6">
          <label for="about" class="block text-sm font-medium text-gray-700">
            Mensaje Personalizado
          </label>
          <div class="mt-1">
            <textarea v-model="message" id="about" name="about" rows="3" class="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md"></textarea>
          </div>
          <p class="mt-2 text-sm text-gray-500">El mensaje será en formato audio.</p>
        </div>

        <div v-if="false" class="sm:col-span-6 mt-3">
          <label for="about" class="block text-sm font-medium text-gray-700">
            Fecha de envío
          </label>
          <div  class="mt-1">
            <v-date-picker v-model="date">
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="bg-white border px-2 py-1 rounded"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </div>
          <p class="mt-2 text-sm text-gray-500">Seleccioná el dia que querés que lo enviemos.</p>
        </div>
      </div>
      <div class="mt-5 sm:mt-6">
        <button @click="addVoiceMessage()" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">
          Agregar por ${{price}}
        </button>
      </div>
    </div>
  </div>
</div>

<div v-if="currentAddon === 'custom-sign'" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
      <div>
        <div class="sm:col-span-6">
          <label for="about" class="block text-sm font-medium text-gray-700">
            Dedicación Personal
          </label>
          <div class="mt-1">
            <textarea v-model="message" id="about" name="about" rows="3" class="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md"></textarea>
          </div>
          <p class="mt-2 text-sm text-gray-500">Una dedicatoria con una firma personal del brazo derecho y/o izquierdo de Hernán. </p>
        </div>

        <div v-if="false" class="sm:col-span-6 mt-3">
          <label for="about" class="block text-sm font-medium text-gray-700">
            Fecha de envío
          </label>
          <div  class="mt-1">
            <v-date-picker v-model="date">
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="bg-white border px-2 py-1 rounded"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </div>
          <p class="mt-2 text-sm text-gray-500">Seleccioná el dia que querés que lo enviemos.</p>
        </div>
      </div>
      <div class="mt-5 sm:mt-6">
        <button @click="addSign()" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">
          Agregar por ${{price}}
        </button>
      </div>
    </div>
  </div>
</div>

<div v-if="currentAddon === 'message-card'" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
      <div>
        <div class="sm:col-span-6">
          <label for="about" class="block text-sm font-medium text-gray-700">
            Dedicación Personal
          </label>
          <div class="mt-1">
            <textarea v-model="message" id="about" name="about" rows="3" class="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md"></textarea>
          </div>
          <p class="mt-2 text-sm text-gray-500">Una dedicatoria con una firma personal del brazo derecho y/o izquierdo de Hernán. </p>
        </div>

        <div v-if="false" class="sm:col-span-6 mt-3">
          <label for="about" class="block text-sm font-medium text-gray-700">
            Fecha de envío
          </label>
          <div  class="mt-1">
            <v-date-picker v-model="date">
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="bg-white border px-2 py-1 rounded"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </div>
          <p class="mt-2 text-sm text-gray-500">Seleccioná el dia que querés que lo enviemos.</p>
        </div>
      </div>
      <div class="mt-5 sm:mt-6">
        <button @click="addMessageCard()" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">
          Agregar por ${{price}}
        </button>
      </div>
    </div>
  </div>
</div>




<!-- This example requires Tailwind CSS v2.0+ -->
<div class="bg-white shadow overflow-hidden sm:rounded-md my-5">
  <ul class="divide-y divide-gray-200">
    <li>
      <a href="#" class="block hover:bg-gray-50">
        <div class="px-4 py-4 flex items-center sm:px-6  flex-col md:flex-row">
          <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
            <div class="">
              <div class="flex flex-col text-sm">
                <div class="flex items-center text-sm text-gray-500">
                  <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 mr-1.5 h-5 w-5 text-orange-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7" />
                  </svg>
                  <p class="font-medium text-gray-900 ">Audio Personalizado de Hernán Casciari</p>
                </div>
                <p class="ml-1 flex flex-shrink-0 text-xs font-normal text-gray-500 italic">
                  Desde <w-price class="ml-1" :value="100"/>
                </p>
              </div>
              <div class="mt-2 flex">
                <div class="flex items-center text-sm text-gray-500 md:pr-10">
                  <p class="">
                    Agrega una sorpresa a tu compra una grabación personalizada, con un limite de 500 caracteres, con un precio que se correlaciona con el cariño que le tenes a tu amigo.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="ml-5 flex-shrink-0 mt-3 md:mt-0">
              <button v-if="!exists('voice-message')" @click="showModal('voice-message')" type="button" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
                <!-- Heroicon name: solid/mail -->
              <svg xmlns="http://www.w3.org/2000/svg" class="-ml-0.5 mr-2 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
                Agregar
              </button>
              <button v-else @click="showModal('voice-message')" type="button" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
                <svg xmlns="http://www.w3.org/2000/svg"  class="-ml-0.5 mr-2 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                </svg>
                Editar
              </button>
          </div>
        </div>
      </a>
    </li>
    <li>
      <a href="#" class="block hover:bg-gray-50">
        <div class="px-4 py-4 flex items-center sm:px-6  flex-col md:flex-row">
          <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
            <div class="">
              <div class="flex flex-col text-sm">
                <div class="flex items-center text-sm text-gray-500">
                  <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 mr-1.5 h-5 w-5 text-orange-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                  </svg>
                  <p class="font-medium text-gray-900 ">Firma del Autor</p>
                </div>
                <p class="ml-1 flex flex-shrink-0 text-xs font-normal text-gray-500 italic">
                  Solo <w-price class="ml-1" :value="999"/>
                </p>
              </div>
              <div class="mt-2 flex">
                <div class="flex items-center text-sm text-gray-500 md:pr-10">
                  <p class="">
                    Agrega una dedicación especial a tu compra, con un limite de 500 caracteres.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="ml-5 flex-shrink-0 mt-3 md:mt-0">
              <button v-if="!exists('custom-sign')" @click="showModal('custom-sign')" type="button" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
                <!-- Heroicon name: solid/mail -->
              <svg xmlns="http://www.w3.org/2000/svg" class="-ml-0.5 mr-2 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
                Agregar
              </button>
              <button v-else @click="showModal('custom-sign')" type="button" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
                <svg xmlns="http://www.w3.org/2000/svg"  class="-ml-0.5 mr-2 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                </svg>
                Editar
              </button>
          </div>
        </div>
      </a>
    </li>
    <li>
      <a href="#" class="block hover:bg-gray-50">
        <div class="px-4 py-4 flex items-center sm:px-6  flex-col md:flex-row">
          <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
            <div class="">
              <div class="flex flex-col text-sm">
                <div class="flex  items-center text-sm text-gray-500">

                  <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 mr-1.5 h-5 w-5 text-orange-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
</svg>
                  <p class="font-medium text-gray-900 ">Tarjeta con Mensaje</p>
                </div>
                <p class="ml-1 flex flex-shrink-0 text-xs font-normal text-gray-500 italic">
                  Solo <w-price class="ml-1" :value="299"/>
                </p>
              </div>
              <div class="mt-2 flex">
                <div class="flex items-center text-sm text-gray-500 md:pr-10">
                  <p class="">
                    Una tarjeta con un texto impreso de tu autoria/eleccion.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="ml-5 flex-shrink-0 mt-3 md:mt-0">
              <button v-if="!exists('message-card')" @click="showModal('message-card')" type="button" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
                <!-- Heroicon name: solid/mail -->
              <svg xmlns="http://www.w3.org/2000/svg" class="-ml-0.5 mr-2 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
                Agregar
              </button>
              <button v-else @click="showModal('message-card')" type="button" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
                <svg xmlns="http://www.w3.org/2000/svg"  class="-ml-0.5 mr-2 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                </svg>
                Editar
              </button>
          </div>
        </div>
      </a>
    </li>
  </ul>
</div>

<!-- This example requires Tailwind CSS v2.0+ -->
<div class="bg-white border sm:rounded-lg relative hidden">
  <div class="px-4 py-5 sm:p-6">
    <h3 class="text-lg leading-6 font-medium text-gray-900" id="renew-subscription-label">
      Agregar audio personalizado de Hernán Casciari
    </h3>
    <span class="absolute right-0 top-0 m-3 inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800">
  Desde $10.00
</span>
    <div class="mt-2 sm:flex sm:items-start sm:justify-between">
      <div class="max-w-xl text-sm text-gray-500">
        <p id="renew-description">
          Un mensaje con un limite de 500 caracteres, con un precio que se correlaciona con el cariño que le tenes a tu amigo.
        </p>
      </div>
      <div class="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
        <!-- Enabled: "bg-orange-600", Not Enabled: "bg-gray-200" -->
        <button type="button" class="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500" role="switch" aria-checked="false" aria-labelledby="renew-subscription-label">
          <span class="sr-only">Use setting</span>
          <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
          <span aria-hidden="true" class="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
        </button>
      </div>
    </div>
  </div>
  <div class="px-4">
            <div class="sm:col-span-6">
          <label for="about" class="block text-sm font-medium text-gray-700">
            Mensaje Personalizado
          </label>
          <div class="mt-1">
            <textarea v-model="message" id="about" name="about" rows="3" class="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md"></textarea>
          </div>
          <p class="mt-2 text-sm text-gray-500">El mensaje será en formato audio.</p>
        </div>

            <div class="sm:col-span-6 mt-3">
          <label for="about" class="block text-sm font-medium text-gray-700">
            Fecha de envío
          </label>
          <div class="mt-1">
                    <v-date-picker v-model="date">
  <template v-slot="{ inputValue, inputEvents }">
    <input
      class="bg-white border px-2 py-1 rounded"
      :value="inputValue"
      v-on="inputEvents"
    />
  </template>
</v-date-picker>
          </div>
          <p class="mt-2 text-sm text-gray-500">Seleccioná el dia que querés que lo enviemos.</p>
        </div>




  </div>

  <div class="mt-3 text-right m-3">
    <button type="button" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
  <!-- Heroicon name: solid/mail -->
<svg xmlns="http://www.w3.org/2000/svg" class="-ml-0.5 mr-2 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>
  Agregar ${{price}}
</button>
  </div>


</div>



</div>
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      date: null,
      priceChar: 10,
      message: "",
      addons: [],
      currentAddon: null,
    }
  },
  computed: {
    price() {
      if(this.currentAddon === "voice-message") {
        return this.message.length * this.priceChar
      }
      if(this.currentAddon === "custom-sign") {
        return 299
      }
      return 999;
    },
    totalPrice(){
      return this.addons.reduce((total, addon) => {
        return total + addon.price
      }, 0)
    }
  },
  watch: {
    addons: {
      deep: true,
      handler(newVal) {
          this.$emit("input", {
            addons: newVal,
            totalPrice: this.totalPrice
          });
      }
    }
  },
  methods: {
    showModal(key){
      const exist = this.addons.find(addon => addon.type === key)
      if(exist)
        this.message = exist.message
      else
        this.message = "";
      this.currentAddon = key;
    },
    exists(key){
      return this.addons.findIndex(addon => addon.type === key) !== -1;
    },
    addVoiceMessage() {
      let exists = this.addons.findIndex(addon => addon.type === "voice-message")
      console.log(exists)
      if(exists > -1){
        console.log("Splice")
        this.addons.splice(exists, 1);
      }
      this.addons.push({
        type: "voice-message",
        message: this.message,
        price: this.price
      })
      this.currentAddon = null
      this.message = "";
    },
    addSign() {
      let exists = this.addons.findIndex(addon => addon.type === "custom-sign")
      console.log(exists)
      if(exists > -1){
        this.addons.splice(exists, 1);
      }
      this.addons.push({
        type: "custom-sign",
        message: this.message,
        price: this.price
      })
      this.currentAddon = null
      this.message = "";
    },
    addMessageCard() {
      let exists = this.addons.findIndex(addon => addon.type === "message-card")
      console.log(exists)
      if(exists > -1){
        this.addons.splice(exists, 1);
      }
      this.addons.push({
        type: "message-card",
        message: this.message,
        price: this.price
      })
      this.currentAddon = null
      this.message = "";
    }
  },
}
</script>
